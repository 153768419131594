<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title>
                  {{`${edit?"Update":"Add"} Driver`}}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-select outlined dense label= "Vehicle No." :items="['Ba 205', 'Ba 206']">

                            </v-select>

                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined dense label= "Name">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined dense label="Present Address">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined dense label="Permanent Address">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined dense label="Liscence Number">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined dense label="Phone">

                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="dialog = false;"  class="text-gray btn btn-standard">Cancel</v-btn>
                                <v-btn v-if="checkIsAccessible('driver', 'create')" depressed :loading="isBusy"   class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>

                        </v-col>

                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import VideoGalleryService from "@/core/services/videoGallery/VideoGalleryService";

    const videoGallery = new VideoGalleryService();
    export default {
        validations: {
            video: {
                title: {required}
            }
        },
        data() {
            return {
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                video: {
                    title: null,
                    video_url: null,
                    tags: null,
                    is_active: true
                }
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getVideo(id)
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;

            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, getVideo(id) {
                videoGallery.show(id).then(response => {
                    this.video = response.data.video
                    if (this.video.tags) {
                        this.video.tags = this.video.tags.split(',');
                    }
                })
            },
            generateFd() {
                // console.log(this.video)
                let fd = new FormData();
                for (let key in this.video) {
                    if (key == "is_active" && this.video["is_active"]) {
                        fd.append("is_active", this.video ? 1 : 0);
                    } else {
                        if (this.video[key] != null) {
                            fd.append(key, this.video[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.video.$touch();
                if (this.$v.video.$error) {
                    setTimeout(() => {
                        this.$v.video.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.updateAlbum(fd);
                    } else {
                        this.createAlbum(fd);
                    }
                }
            },
            updateAlbum(fd) {
                this.isBusy = true;
                videoGallery
                    .update(this.video.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createAlbum(fd) {
                this.isBusy = true;
                videoGallery
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.video.$reset();
                this.edit = false;
                this.video = {
                    title: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh_video");
            }
        }
    };
</script>
