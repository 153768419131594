<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-md-6 col-sm-12 col-lg-6">
                <h4>
                  Manage Driver
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                Drivers
              </div>

              <div class="col-md-6 col-sm-12 col-lg-6 text-right"  v-if="checkIsAccessible('driver', 'create')">
                <a @click="addVideo" class="btn btn-primary">

                  Add Driver
                </a>
              </div>
              <form @submit.prevent="getVideosGallery" class="row mt-5 mb-5 bg-filter">
                <div class="col-4">
                  <v-text-field label="Title"
                                type="text"
                                dense
                                outlined
                                v-model="search.title"></v-text-field>
                </div>
                <div class="col-4">
                  <v-select outlined dense :items="active_statuses" item-text="title" item-value="value"
                            v-model="search.active"></v-select>
                </div>
                <div class="col-4" v-if="checkIsAccessible('driver', 'create')">
                  <button class="btn btn-primary" @click="getVideosGallery">Search</button>
                </div>
              </form>


              <table class="table mt-3">
                <thead class="text-left">
                <th>Title</th>
                <th>Status</th>
                <th>Action</th>
                </thead>


                <draggable @change="sort" v-model="videos" class="text-left" tag="tbody">
                  <tr v-for="(album, index) of videos" :key="album.title">
                    <td scope="row">
                      <a href="#" @click="editVideo(album.id)" class="mr-2"> {{ album.title }}</a>
                      <i class="fas fa-circle"
                         :class="album.is_active?'dot-active':'dot-inactive'"></i>
                    </td>
                    <td>
                      <span
                          :class="album.is_active?'badge badge-success':'badge badge-danger'">{{ album.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <!--                                                                              <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                                                <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                                                        <span class="navi-icon">-->
                          <!--                                                                                          <i class="fas fa-eye"></i>-->
                          <!--                                                                                        </span>-->
                          <!--                                                                                  <span class="navi-text"> View </span>-->
                          <!--                                                                                </a>-->
                          <!--                                                                              </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('driver', 'edit')">
                            <a href="#" class="navi-link" @click="editVideo(album.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link"
                               @click="deleteVideo(album.id)" v-if="checkIsAccessible('driver', 'delete')">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                              <span class="navi-text">  Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                </draggable>

              </table>
            </div>
            <div class="row">
              <div class="col-12" v-if="total>perPage">
                <b-pagination
                    @input="getVideosGallery"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="album" @refresh_video="getVideosGallery"></create-and-update>
      <v-dialog v-model="videoModel" max-width="600">
        <v-card>
          <v-card-title>
            {{ video.title }}
          </v-card-title>
          <v-card-text>
            <div v-html="video.video_html">

            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import VideoGalleryService from "@/core/services/videoGallery/VideoGalleryService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable'

const videoGallery = new VideoGalleryService();
export default {
  name: "table-example",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'Inactive', value: "in_active"}
      ],
      videos: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active"
      },
      videoModel: false,
      video: {},
      dragging: false
    };
  },
  mounted() {
    this.getVideosGallery();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Album", route: "wizard-1"},
      {title: "Album"}
    ]);
  },
  methods: {
    getVideosGallery() {
      videoGallery
          .paginate(this.search)
          .then(response => {
            this.videos = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(error => {
          });
    },
    addVideo() {
      this.$refs["album"].showModal();
    },
    editVideo(id) {
      this.$refs["album"].showModal(id);
    },

    deleteVideo(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            videoGallery
                .delete(id)
                .then(response => {
                  this.getVideosGallery();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    sort() {
      videoGallery.sort(this.videos).then((res) => {
        this.$snotify.success("Sorted");
        this.getVideosGallery()
      }).catch(err => {
        this.$snotify.success("Sorted Error");
      })
    },
    detail(video) {
      this.videoModel = true;
      this.video = video;
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
